@import "fonts/Anton/anton";

body, html {
  line-height: 1.55rem;
  font-size: 1.25rem;
  background-color: whitesmoke;
  overflow: hidden;
  font-family: Anton, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;

  a {
    color: black;
    text-decoration: none;
    background-color: whitesmoke;
    padding: 0 0.25em;
    outline-color: #e86969;

    &:hover,
    &:active {
      color: #e86969;
    }
  }

  #page {
    width: 100vw;
    height: 100vh;
    background: url(/img/alive-compressed-2.gif) center center no-repeat;

    &.iphone {
      width: 100vw;
      height: 86vh;

      #contacts {
        min-height: 86vh;
      }

      #email, .iphone #links {
        max-height: 86vh;
      }
    }

    #contacts {
      max-width: 380px;
      min-height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 1em;
    }

    #email {
      max-width: 150px;
      height: 500px;
      max-height: 100vh;
      text-align: left;
      list-style: none;
    }

    #links {
      margin: 0;
      max-width: 150px;
      height: 500px;
      max-height: 100vh;
      text-align: right;
      list-style: none;
    }
  }
}